










































































































































import { Control } from "@/models/control";
import router from "@/router";
import store from "@/store";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Login extends Vue {
  public controls: Control[] = [
    {
      name: "nick",
      label: "Никнейм",
      placeholder: "Введите никнейм",
      valid: false,
      value: "",
      needValidate: true,
      invalidMessage: "",
      validators: [
        (control: Control): boolean => {
          const minLength = 3;
          const isValid = control.value.length > minLength;
          if (!isValid) {
            control.invalidMessage = "Нужно больше букв";
          }
          return isValid;
        }
      ]
    },
    {
      name: "avatar-image",
      label: "Аватар",
      placeholder: "Вставьте ссылку на изображение",
      needValidate: false,
      valid: true,
      value: ""
    }
  ];

  public isFormValid() {
    return this.controls.every((control: Control) => this.validate(control));
  }

  public handleSubmit() {
    const nameIndex = 0;
    const avatarIndex = 1;
    if (this.isFormValid()) {
      store.commit("login", {
        name: this.controls[nameIndex].value,
        avatarLink: this.controls[avatarIndex].value
      });
      router.push({ path: "messages" });
    }
    this.$emit("login");
  }

  public handleInput(event: InputEvent, curControl: Control) {
    this.controls = this.controls.map((control: Control) => {
      if (control !== curControl) {
        return control;
      }

      control.value = (event.target as any).value;
      control.valid = this.validate(control);

      return control;
    });
  }

  private validate(control: Control): boolean {
    if (!control.needValidate) {
      return true;
    }

    // Вернёт true если все валидаторы вернут true
    control.valid = (control &&
      control.validators &&
      control.validators.every(validator => validator(control))) as boolean;
    return control.valid;
  }
}
